<template>
  <TopNav></TopNav>
  <h1>İki Tarih Arasındaki Gün Sayısını Hesaplama</h1>
  <fieldset>
    <legend>İki Tarih Arasındaki Gün Sayısını Hesaplama Aracı</legend>
    <label for="baslangictarihi">Başlangıç Tarihi:</label> <input type="date" v-model="baslangic" id="baslangictarihi">
    <br>
    <label for="bitisctarihi">Bitiş Tarihi:</label> <input type="date" v-model="bitis" id="bitisctarihi">
    <br><br>
    Fark: <span class="blue">{{ fark() }} gün</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'
import moment from 'moment'

export default {
  name: "IkiTarihArasindakiGunSayisi",
  data() {
    return {
      baslangic: "",
      bitis: "",
    }
  },
  components: {
    TopNav
  },
  methods: {
    fark: function () {
      let a = moment(this.baslangic);
      let b = moment(this.bitis);
      let fark = b.diff(a, 'days', true);
      let gun = new Number(fark);
      return gun.toLocaleString('tr-TR');
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
    display: inline-block;
    min-width: 145px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
